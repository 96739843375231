<template>
    <div class='d-flex align-items-center h-100'>
        <div class="stap-inner pb-5">
          <h1><span class='stap-number'>5.</span> Ervaring</h1>
          <h5 class='stap-beschrijving mb-5'>Om een juiste lesindeling te kunnen maken, hebben we zoveel mogelijk informatie nodig over de {{discipline}}ervaring van {{name}}. </h5>

            <div class="form-topic d-block d-md-flex align-items-center justify-content-between">
              <h5 class='mb-md-0'>Heeft {{name}} eerder {{disciplinePiste}}?</h5>
              <div class='d-flex gap-3 gap-md-0'>
                <div class="card-option"
                :class='eerderIsSelected(true)'
                @click='eerderSet(true)'>
                  Ja
                </div>
                <div class="card-option"
                :class='eerderIsSelected(false)'
                @click='eerderSet(false)'>
                  Nee
                </div>
              </div>
            </div>

            <div v-if='eerder' class="form-topic d-block d-md-flex align-items-center justify-content-between">
              <h5 class='mb-md-0'>Heeft {{name}} recent (de afgelopen 2 seizoenen) een cursus gevolgd bij Skipiste Nieuwegein?</h5>
              <div class='d-flex gap-3 gap-md-0'>
                <div class="card-option"
                :class='afgelopenSeizoenIsSelected(true)'
                @click='afgelopenSeizoenSet(true)'>
                  Ja
                </div>
                <div class="card-option"
                :class='afgelopenSeizoenIsSelected(false)'
                @click='afgelopenSeizoenSet(false)'>
                  Nee
                </div>
              </div>
            </div>
            <div class="form-topic" v-if='!afgelopenSeizoen && eerder && afgelopenSeizoen !== null'>
                <div class="d-block d-md-flex align-items-center justify-content-between">
                    <label class='mb-1 mb-md-0' for="aantal-weken">Hoeveel weken sneeuw-ervaring heeft {{name}}{{disciplineVerb}}?</label>
                    <input v-model.lazy="aantalWeken" id='aantal-weken' min='0' type='number' class='form-control'/>
                </div>
                <hr>
                <div class='d-block d-md-flex align-items-center justify-content-between'>
                    <label class='mb-1 mb-md-0' for="aantal-uren">In welk jaar heeft {{name}}{{disciplineLast}}?</label>
                    <select class="form-select" v-model="laatsteJaar">
                        <option value="null">Niet van toepassing</option>
                        <option
                        v-for="jaar in 10"
                        :key="jaar"
                        :value="new Date().getFullYear()  - jaar + 1">{{new Date().getFullYear() - jaar + 1}}</option>
                    </select>
                </div>
            </div>
            <div class="form-topic" v-if='!afgelopenSeizoen && eerder && afgelopenSeizoen !== null'>
                <label class='mb-1 mb-md-0'>Op welke pistes heeft {{name}}{{disciplinePiste}}?</label>
                <div
                    class="form-check"
                    v-for='piste in pistesOpties'
                    :key="piste.id"
                    :class='pisteIsSelected(piste.id)'
                    >
                    <input type="checkbox" v-bind:id="'piste-'+piste.id" class='form-check-input' :value='piste.titel' v-model="pistes">
                    <label v-bind:for="'piste-'+piste.id">{{piste.titel}}</label>
                </div>
            </div>
            <div class="form-topic" v-if='!afgelopenSeizoen && eerder && afgelopenSeizoen !== null'>
                <label class='mb-1 mb-md-0'>Welke technieken beheerst {{name}}?</label>
                <div
                    class="form-check"
                    v-for='techniek in techniekenOpties'
                    :key="techniek.id"
                    :class='techniekIsSelected(techniek.id)'
                    >
                    <input type="checkbox" v-bind:id="'techniek-'+techniek.id" class='form-check-input' :value="techniek.id" v-model="technieken">
                    <label v-bind:for="'techniek-'+techniek.id">{{techniek.titel.replace(/^\w/, (c) => c.toUpperCase())}}</label>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {
  name: 'StapErvaring',
  methods: {
    pisteIsSelected: function (id) {
      return {
        selected: this.pistes.includes(id)
      }
    },
    techniekIsSelected: function (id) {
      return {
        selected: this.technieken.includes(id)
      }
    },
    setDiscipline: function (e, discipline) {
      this.loading = true
      this.$store.dispatch('aanmelding/setDiscipline', discipline)
      this.loading = false
    },
    isSelected: function (id) {
      return {
        selected: this.$store.state.aanmelding.discipline && this.$store.state.aanmelding.discipline.id === id
      }
    },
    eerderIsSelected: function (id) {
      return {
        selected: this.$store.state.aanmelding.ervaring.eerder === id
      }
    },
    eerderSet: function (value) {
      this.$store.dispatch('aanmelding/setErvaring', {
        key: 'eerder',
        value
      })
      if (value === false) {
        this.afgelopenSeizoenSet(false)
        this.$router.push({ name: 'StapMateriaal' })
      }
    },
    afgelopenSeizoenIsSelected: function (id) {
      return {
        selected: this.$store.state.aanmelding.ervaring.afgelopenSeizoen === id
      }
    },
    afgelopenSeizoenSet: function (value) {
      this.$store.dispatch('aanmelding/setErvaring', {
        key: 'afgelopenSeizoen',
        value
      })
      if (value) {
        this.$router.push({ name: 'StapMateriaal' })
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('options/getTechnieken', this.$store.state.aanmelding.id).then(() => {
      if (this.$store.state.aanmelding.ervaring.technieken.length === 0) {
        this.$store.dispatch('aanmelding/setErvaring', {
          key: 'technieken',
          value: []
        })
      }
    })
    this.$store.dispatch('options/getPistes', this.$store.state.aanmelding.id).then(() => {
      if (this.$store.state.aanmelding.ervaring.pistes.length === 0) {
        this.$store.dispatch('aanmelding/setErvaring', {
          key: 'pistes',
          value: []
        })
      }
    })
  },
  computed: {
    aanmelding () {
      return this.$store.state.aanmelding
    },
    eerder: function () {
      return this.$store.state.aanmelding.ervaring.eerder
    },
    afgelopenSeizoen: function () {
      return this.$store.state.aanmelding.ervaring.afgelopenSeizoen
    },
    aantalWeken: {
      get: function () {
        return this.$store.state.aanmelding.ervaring.aantalWeken
      },
      set: function (value) {
        this.$store.dispatch('aanmelding/setErvaring', {
          key: 'aantalWeken',
          value: value
        })
      }
    },
    laatsteJaar: {
      get: function () {
        return this.$store.state.aanmelding.ervaring.laatsteJaar
      },
      set: function (value) {
        this.$store.dispatch('aanmelding/setErvaring', {
          key: 'laatsteJaar',
          value: value
        })
      }
    },
    pistes: {
      get: function () {
        return this.$store.state.aanmelding.ervaring.pistes
      },
      set: function (value) {
        this.$store.dispatch('aanmelding/setErvaring', {
          key: 'pistes',
          value: value
        })
      }
    },
    technieken: {
      get: function () {
        return this.$store.state.aanmelding.ervaring.technieken
      },
      set: function (value) {
        this.$store.dispatch('aanmelding/setErvaring', {
          key: 'technieken',
          value: value
        })
      }
    },

    name () {
      return this.$store.state.aanmelding.client.firstname
    },
    disciplineVerb () {
      switch (this.$store.state.aanmelding.activiteit.titel) {
        case 'Ski':
          return ' met skiën'
        case 'Snowboard':
          return ' met snowboarden'
        default:
          return ' met deze activiteit'
      }
    },
    disciplinePiste () {
      switch (this.$store.state.aanmelding.activiteit.titel) {
        case 'Ski':
          return ' geskied'
        case 'Snowboard':
          return ' gesnowboard'
        default:
          return ' ervaring'
      }
    },
    disciplineLast () {
      switch (this.$store.state.aanmelding.activiteit.titel) {
        case 'Ski':
          return ' voor het laatst geskied'
        case 'Snowboard':
          return ' voor het laatst gesnowboard'
        default:
          return ' voor het laatst de activiteit beoefent waarvoor deze aanmelding wordt ingediend'
      }
    },
    discipline () {
      switch (this.$store.state.aanmelding.activiteit.titel) {
        case 'Ski':
          return 'ski-'
        case 'Snowboard':
          return 'snowboard'
        default:
          return ''
      }
    },
    fullfill () {
      return true
    },
    pistesOpties () {
      return this.$store.state.options.pistes
    },
    techniekenOpties () {
      return this.$store.state.options.technieken
    },
    disciplineOpties () {
      return this.$store.state.options.discipline
    }
  }
}
</script>
<style scoped>

    label{
        font-size: 1.1rem;
        font-weight: 500;
    }
    .form-topic h5{
      font-size: 1.1rem;
    }
    .form-select, .form-control{
        font-size: 1.3rem;
        font-weight: 500;
        border-radius: 10px;
        width: 200px;
    }
    @media screen and (max-width: 768px) {
      .card-option{
        margin-bottom: 0;
      }
      .form-select, .form-control{
        width: 100%;
        padding: 12px;
      }
      .form-check{
        padding: 0;
      }
      .form-check label{
        background: #fff;
        width: 100%;
        border-radius: 5px;
        padding: 8px;
        margin-bottom: 4px;
        transition: all 0.1s ease-in-out;
        cursor: pointer;
      }
      /* hide checkbox in form-check */
      .form-check input[type="checkbox"]{
        display: none;
      }

      .form-check.selected label{
        background: var(--skipiste-color);
        color: #fff;
        font-weight: bold;
      }
    }
</style>
